export default {
  text: '#868786',
  // primary: '#de1a62',
  primary: '#940809',
  secondary: '#abce1e',
  yellowAccent: '#ffc509',
  orangeAccent: '#f49f0c',
  blueAccent: '#1db6ba',
  background: '#fff5e9',
  backgroundSecondary: '#fff5e9',
  light: '#fdfdfd',
  dark: '#484848',
}
