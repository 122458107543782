export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      // backgroundColor: 'transparent',
      // display: 'none',
      color: 'white',
      position: 'fixed',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      '.navItem a:hover': {
        color: 'primary',
      },
    },
    '.containerScrolled': {
      background: 'unset',
      backgroundColor: 'primary',
      color: 'white',
      justifyContent: 'flex-start',
      padding: ['1rem', '', '', '0.5rem'],
      // '.navItem a:hover': {
      //   color: 'primary',
      // },
    },
    '.smallNavMenu': {
      flex: 'unset',
      order: '2',
    },
    '.smallNavMenu > div': {
      borderColor: 'black',
      border: 'none',
      '.navItem': {
        a: {
          fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem'],
          ':hover': {
            color: 'secondary',
          },
        },
      },
    },
    '.smallNavMenuRight': {
      margin: '0 auto 0 0',
    },

    '.navItem': {
      a: {
        fontSize: ['2rem', '2rem', '2rem', '2rem'],
        ':hover': {
          color: 'secondary',
        },
      },
    },
    '.hamburger > div': {
      backgroundColor: 'secondary',
    },
    '.navMenuOpen': {
      backgroundColor: 'orangeAccent',
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      svg: {
        path: {
          fill: 'black',
        },
      },
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: '0 auto 0 0',
    },
    '.logo': {
      img: {
        maxHeight: ['65px', '100px'],
        // filter: 'brightness(0) invert(1)',
      },
    },
    '.logoScrolled': {
      maxHeight: ['50px', '50px'],

      img: {
        maxHeight: ['50px', '50px'],
        filter: 'unset',
      },
    },
  },

  footer: {
    borderTop: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'primary',
    maxWidth: 'unset',
    color: 'white',
    '.logo': {
      maxHeight: '80px',
    },
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'white',
        },
      },
    },
    '.aboutColumn': {
      p: {
        color: 'white',
      },
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(1)',
    },

    '.multiButtonContainer a': {
      color: 'white',
    },
  },

  ctaWidget: {
    a: {
      backgroundColor: 'orangeAccent',
      borderRadius: ['', '', '100px'],
    },
  },

  // ? ====================
  // ? ===  reuseables  ===
  // ? ====================

  title: {
    color: 'primary',
    fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
    order: '2',
  },
  subtitle: {
    color: 'secondary',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    width: 'fit-content',
    order: '1',
    fontFamily: 'body',
  },
  text: {
    color: 'text',
    order: '3',
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.slick-arrow': {
      display: 'none !important',
    },
    '.title': {
      fontSize: ['2.2rem', '', '2.5rem'],
      color: 'secondary',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    color: 'light',
    '.shoutWidget': {
      display: 'none',
    },
    '.slick-dots li.slick-active button:before': {
      color: 'secondary',
    },
    '.slick-dots li button:before': {
      fontSize: '15px',
    },

    '.hero_content_container': {
      // display: 'none',
      // marginBottom: ['', '', '', '7rem'],
      padding: ['2rem', '', '', '4.5rem', '5.5rem'],
      left: '50%',
      margin: '0rem',
      transform: 'translate(-50%,-50%)',

      '.title': {
        variant: 'customVariants.title',
        order: '2',
        color: 'white',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        order: '1',
      },

      '.text': {
        variant: 'customVariants.text',
        order: '3',
        color: 'white',
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        marginTop: '1rem',
        '.ctaButton': {
          variant: 'buttons.primary',
        },
      },
    },
  },

  homepageHeroShout: {
    display: 'none',
    position: 'absolute',
    bottom: ['0rem', '', '1rem'],
    left: '0rem',
    '.shoutWidget': {
      backgroundColor: ['#f0f0e6', '', 'rgb(240 240 230 / 66%)'],
      maxHeight: '200px',
      maxWidth: ['unset', '', '', '40%'],
      '.imageContainer img': {
        border: 'none',
      },
      '.title': {
        fontSize: '1rem',
        fontFamily: 'heading',
        textDecoration: 'underline',
      },
      '.text': {
        fontSize: '1.5rem',
        color: 'primary',
      },
    },
  },

  homepageShoutSection: {
    flexWrap: ['', '', 'wrap', 'wrap'],
    '.text': {
      width: ['', '', '60%'],
      padding: ['', '2rem 3rem'],
      textAlign: 'left',
      color: 'white',
      backgroundColor: 'orangeAccent',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: '80px',
      fontSize: ['2rem', '', '', '2.5rem'],
    },
    '.title': {
      width: ['', '', '100%'],
      justifyContent: 'center',
      textDecoration: 'underline',
      backgroundColor: 'primary',
      display: ['flex', '', 'none', ''],
    },
    '.date': {
      order: ['', '', '5'],
    },
  },

  homepageAboutSection: {
    margin: '2rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      backgroundColor: 'backgroundSecondary',
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        order: '3',
      },
      a: {
        order: '4',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset',
      },
      img: {
        height: '65vh',
      },
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageAtmosphere: {
    '.title': {
      variant: 'customVariants.title',
      order: '1',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
      fontSize: ['2rem', '2.5rem', '3rem'],
      borderBottom: 'none',
      color: 'blueAccent',
      fontFamily: 'body',
    },
  },

  homepageMenuSection: {
    margin: '2rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      backgroundColor: 'backgroundSecondary',
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        order: '3',
      },
      a: {
        order: '4',
        '.ctaButton': {
          variant: 'buttons.secondary',
        },
      },
    },
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset',
      },
      img: {
        height: '65vh',
      },
    },
  },

  homepageGallerySection: {
    variant: 'customVariants.homepageAboutSection',
  },
  homepageEventsSection: {
    variant: 'customVariants.homepageMenuSection',
  },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important',
    },
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    maxWidth: 'unset',
    '.menuContainer': {
      paddingBottom: '1rem',
    },
    '.menuSectionTitle': {
      fontSize: ['2rem', '2.5rem', '3rem'],
      color: 'secondary',
    },
    '.allInContainerWrapper': {},
    '.menuItemInnerContainer': {},
    '.menuItemImageContainer': {},
    '.menuItemName': {
      color: 'blueAccent',
    },
    '.menuItemDescription': {
      color: 'primary',
    },
    '.menuItemContentContainer': {
      margin: '0rem',
    },
    '.dropdownContainer': {
      display: 'none',
    },
    '.section-cell': {
      backgroundColor: 'backgroundSecondary',
      borderRadius: '0px',
      ':hover': {
        '.cellImage': {},
      },
    },
    '.cellImageContainer': {
      borderRadius: '0px',
      '.imageFill': {},
      img: {
        borderRadius: '0px',
      },
    },
    '.menuCell, .cellImage': {},
    '.menuCell': {
      backgroundColor: 'backgroundSecondary',
      borderRadius: '0px',
    },
    '.cellName': {
      color: 'primary',
      borderTop: '2px solid',
      borderBottom: '2px solid',

      borderColor: 'secondary',
    },
    '.menuItemContainerImgActive': {},
    '.backToMenuBtn': {
      variant: 'buttons.primary',
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black',
    },
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: 'blueAccent',
    padding: '4rem 1rem',
    color: 'white',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem',
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid white',
        fontFamily: 'body',
        '::placeholder': {
          color: 'white',
          opacity: '0.8',
        },
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid white',
        '::placeholder': {
          color: 'white',
          opacity: '0.8',
        },
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        // borderRadius: '0px',
      },
    },
  },

  locationMap: {
    h3: {
      fontSize: '2rem',
      color: 'primary',
      textDecoration: 'underline',
    },
    iframe: {},
    '.content_container': {
      flexGrow: '1',
      backgroundColor: 'backgroundSecondary',
    },
  },
}
