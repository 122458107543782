export default {
  primary: {
    color: 'primary',
    border: 'solid 2px',
    fontSize: '0.9rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    borderColor: 'secondary',
    backgroundColor: 'secondary',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'light',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 0.5rem',
    color: 'primary',
    border: 'none',
    // borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    ':hover': {
      opacity: '0.8',
      backgroundColor: 'transparent',
      color: 'secondary',
    },
  },
}
